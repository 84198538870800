import React, { useReducer } from 'react'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'

import { useAuth0 } from '../../react-auth0-spa'
import SubscriptionContext from './subscriptionContext'
import SubscriptionReducer from './subscriptionReducer'
import { FETCH_SUBSCRIPTIONS, SET_SUBSCRIPTION_STATE_LOADING } from '../types'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const SubscriptionProvider = ({ children }) => {
  const initialState = {
    subscriptions: [],
    subscriptionStateLoading: false,
  }

  const [state, dispatch] = useReducer(SubscriptionReducer, initialState)

  const { getTokenSilently } = useAuth0()

  const setSubscriptionStateLoading = isSubscriptionStateLoading =>
    dispatch({
      type: SET_SUBSCRIPTION_STATE_LOADING,
      payload: isSubscriptionStateLoading,
    })

  const displayBasicErrorHandling = () =>
    window.alert(
      'Se ha producido un error. Vuelve a intentarlo o ponte en contacto con el servicio de asistencia.',
    )

  const fetchAvailableSitePlans = async () => {
    try {
      setSubscriptionStateLoading(true)

      const firebaseResponse = await axios({
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        data: {
          query: `
          query {
            getPrices {
              stripePriceId
              active
              unitAmountDecimal
              billingScheme
              currency
              interval
              intervalCount
              stripePriceNickName
              product
              trialPeriodDays
            }
          }
        `,
        },
      })

      if (firebaseResponse.data.data.getPrices) {
        const { getPrices } = firebaseResponse.data.data

        await dispatch({
          type: FETCH_SUBSCRIPTIONS,
          payload: getPrices,
        })

        return setSubscriptionStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const createStripeSubscription = async subscriptionName => {
    try {
      setSubscriptionStateLoading(true)

      const accessToken = await getTokenSilently()

      // get the id of the plan to use in the GraphQL mutation
      const { stripePriceId } = state.subscriptions.find(
        sub => sub.stripePriceNickName === subscriptionName,
      )

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            createSubscriptionSession(input: {
              stripePriceId: "${stripePriceId}",
              isEdival: true
            }) {
              stripeCheckoutSessionId
            }
          }
        `,
        },
      })

      const {
        stripeCheckoutSessionId,
      } = firebaseResponse.data.data.createSubscriptionSession

      const stripe = await stripePromise
      const { error } = await stripe.redirectToCheckout({
        sessionId: stripeCheckoutSessionId,
      })

      if (error) {
        throw new Error(error)
      }

      return setSubscriptionStateLoading(false)
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const createBillingPortalSession = async () => {
    try {
      await setSubscriptionStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          query {
            getStripeBillingPortalSession(input: {
              isEdival: true
            }) {
              url
            }
          }
        `,
        },
      })

      const { url } = firebaseResponse.data.data.getStripeBillingPortalSession

      if (url) {
        await setSubscriptionStateLoading(false)
        return url
      } else {
        throw new Error('Error retrieving Stripe billing url')
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions: state.subscriptions,
        subscriptionStateLoading: state.subscriptionStateLoading,
        fetchAvailableSitePlans,
        createStripeSubscription,
        createBillingPortalSession,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider

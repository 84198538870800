import {
  LOAD_USER_STATE,
  UPDATE_USERNAME,
  DELETE_USER,
  UPDATE_USER_PRODUCT_ACCESS,
  SET_USER_STATE_LOADING,
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case LOAD_USER_STATE:
      return {
        ...state,
        username: action.payload.username,
        subscriptionInfo: action.payload.subscriptionInfo,
      }
    case UPDATE_USERNAME:
      return {
        ...state,
        username: action.payload,
      }
    case DELETE_USER:
      return {
        ...state,
        username: '',
        subscriptionInfo: null,
        productAccess: null,
      }
    case UPDATE_USER_PRODUCT_ACCESS:
      return {
        ...state,
        productAccess: action.payload,
      }
    case SET_USER_STATE_LOADING:
      return {
        ...state,
        userStateLoading: action.payload,
      }
    default:
      return state
  }
}

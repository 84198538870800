// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-frequent-questions-js": () => import("./../src/pages/frequentQuestions.js" /* webpackChunkName: "component---src-pages-frequent-questions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-cancel-js": () => import("./../src/pages/payment/cancel.js" /* webpackChunkName: "component---src-pages-payment-cancel-js" */),
  "component---src-pages-payment-success-js": () => import("./../src/pages/payment/success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-we-js": () => import("./../src/pages/we.js" /* webpackChunkName: "component---src-pages-we-js" */)
}


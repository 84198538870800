import React, { useReducer } from 'react'
import axios from 'axios'

import { useAuth0 } from '../../react-auth0-spa'
import UserContext from './userContext'
import UserReducer from './userReducer'
import {
  LOAD_USER_STATE,
  UPDATE_USERNAME,
  DELETE_USER,
  UPDATE_USER_PRODUCT_ACCESS,
  SET_USER_STATE_LOADING,
} from '../types'

const UserProvider = ({ children }) => {
  const initialState = {
    username: '',
    subscriptionInfo: null,
    productAccess: null,
    userStateLoading: false,
  }

  const [state, dispatch] = useReducer(UserReducer, initialState)

  const { user, logout, getTokenSilently } = useAuth0()

  const setUserStateLoading = isUserStateLoading =>
    dispatch({ type: SET_USER_STATE_LOADING, payload: isUserStateLoading })

  const displayBasicErrorHandling = () =>
    window.alert(
      'Se ha producido un error. Vuelve a intentarlo o ponte en contacto con el servicio de asistencia.',
    )

  const fetchUserProductAccess = async () => {
    try {
      await setUserStateLoading(true)

      const accessToken = await getTokenSilently()

      const response = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              getUserProductAccess {
                fluentworlds
              }
            }
          `,
        },
      })

      if (response.data.data.getUserProductAccess) {
        const { getUserProductAccess } = response.data.data

        await dispatch({
          type: UPDATE_USER_PRODUCT_ACCESS,
          payload: getUserProductAccess,
        })

        return setUserStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setUserStateLoading(false)
    }
  }

  const fetchUserFromFirestore = async () => {
    try {
      await setUserStateLoading(true)

      const { sub } = user
      const accessToken = await getTokenSilently()

      const response = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              userById(id: "${sub}") {
                username
                subscriptionInfo {
                  customerId
                }
              }
            }
          `,
        },
      })

      if (response.data.data.userById.username !== null) {
        const { userById } = response.data.data
        await dispatch({
          type: LOAD_USER_STATE,
          payload: userById,
        })

        return setUserStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setUserStateLoading(false)
    }
  }

  const updateUserName = async newUserName => {
    const { sub } = user
    const accessToken = await getTokenSilently()

    await axios({
      method: 'post',
      url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        query: `
          mutation {
            updateUser(input: {id: "${sub}", username:"${newUserName}"}) {
              id
            }
          }
        `,
      },
    })
      .then(response => {
        /*
          TODO: adjust graphql query to return a valid response in data when updating
        */

        return dispatch({
          type: UPDATE_USERNAME,
          payload: newUserName,
        })
      })
      .catch(error => {
        console.error(error)
        displayBasicErrorHandling()
      })
  }

  const deleteUser = async () => {
    const { sub } = user
    const accessToken = await getTokenSilently()

    if (window.confirm(`Are you sure you want to delete your account?`)) {
      await setUserStateLoading(true)

      await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            deleteUser(id: "${sub}") {
              id
            }
          }
            `,
        },
      })
        .then(firebaseResponse => {
          /*
            TODO: adjust graphql query to return a valid response in data when deleting
          */

          setUserStateLoading(false)
          dispatch({
            type: DELETE_USER,
          })
          return logout()
        })
        .catch(error => {
          console.error(error)
          displayBasicErrorHandling()
          return setUserStateLoading(false)
        })
    }
  }

  return (
    <UserContext.Provider
      value={{
        username: state.username,
        subscriptionInfo: state.subscriptionInfo,
        productAccess: state.productAccess,
        userStateLoading: state.userStateLoading,
        fetchUserFromFirestore,
        fetchUserProductAccess,
        updateUserName,
        deleteUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider

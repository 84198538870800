import React from 'react'
import { Auth0Provider } from './src/react-auth0-spa'
import UserProvider from './src/context/user/UserProvider'
import SubscriptionProvider from './src/context/subscription/SubscriptionProvider'

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      client_id={process.env.GATSBY_AUTH0_CLIENT_ID}
      redirect_uri={process.env.GATSBY_WEBSITE_URL + '/profile/'}
      audience={process.env.GATSBY_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
    >
      <UserProvider>
        <SubscriptionProvider>{element}</SubscriptionProvider>
      </UserProvider>
    </Auth0Provider>
  )
}

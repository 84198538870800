// user
export const LOAD_USER_STATE = 'LOAD_USER_STATE'
export const UPDATE_USERNAME = 'UPDATE_USERNAME'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER_PRODUCT_ACCESS = 'UPDATE_USER_PRODUCT_ACCESS'
export const SET_USER_STATE_LOADING = 'SET_USER_STATE_LOADING'

// subscription
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS'
export const SET_SUBSCRIPTION_STATE_LOADING = 'SET_SUBSCRIPTION_STATE_LOADING'

import { FETCH_SUBSCRIPTIONS, SET_SUBSCRIPTION_STATE_LOADING } from '../types'

export default (state, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      }
    case SET_SUBSCRIPTION_STATE_LOADING:
      return {
        ...state,
        subscriptionStateLoading: action.payload,
      }
    default:
      return state
  }
}
